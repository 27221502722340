import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { IntlProvider } from 'react-intl';
import { TrackingProvider } from 'trackingContext';
import updateSecondLevelMenuActiveLink from 'updateSecondLevelMenuActiveLink';
import Homepage from '@Pages/Homepage';
import Workforce from '@Pages/Workforce';
import Press from '@Pages/Press';
import Career from '@Pages/Career';
import Franchise from '@Pages/Franchise';
import Cleaner from '@Pages/Cleaner';
import NotFound from '@Pages/NotFound';
import Logger from 'logger';
import Header from '@Navigation/Header';
import { Navigation, initEventsRel } from '@rel/experience';
import MarketingMessages from './components/MarketingMessages';

// Function to get the component based on route
const getComponent = (route, routeName) => {
  switch (route.component) {
    case 'workforce':
      return function WorkforceComponent(props) {
        return <Workforce {...props} pageType={routeName} />;
      };
    case 'press':
      return function PressComponent(props) {
        return <Press {...props} pageType={routeName} />;
      };
    case 'career':
      return function CareerComponent(props) {
        return <Career {...props} pageType={routeName} />;
      };
    case 'franchise':
      return function FranchiseComponent(props) {
        return <Franchise {...props} pageType={routeName} />;
      };
    case 'cleaner':
      return function CleanerComponent(props) {
        return <Cleaner {...props} pageType={routeName} />;
      };
    case 'homepage':
      return Homepage;
    default:
      return null;
  }
};

const getMarketingMessages = (context) => {
  let marketingMessages = [];
  if (context && context?.data?.marketingMessages) {
    marketingMessages = context.data.marketingMessages;
  } else if (typeof window !== 'undefined' && window.__TF_MARKETING_MESSAGES_DATA__?.marketingMessages) {
    marketingMessages = window.__TF_MARKETING_MESSAGES_DATA__.marketingMessages;
  }

  return marketingMessages;
};

const getTfHeaderProps = (context) => {
  let tfHeaderProps = {};
  if (context && context?.data?.tfHeaderProps) {
    tfHeaderProps = context.data.tfHeaderProps;
  } else if (typeof window !== 'undefined' && window.__TF_HEADER_DATA__?.tfHeaderProps) {
    tfHeaderProps = window.__TF_HEADER_DATA__.tfHeaderProps;
  }

  return tfHeaderProps;
};

// We have to collect all routes outside the App function, because of the pageLoader.
// If we use the pageLoader inside a function, SSR wont load the components.
const routeComponents = [];
// Load the routes for the specific country.
const routesCore = require('../config/core/client.routes.json');

let routesOpCo = {};
try {
  routesOpCo = require(`../config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
} catch (e) {}
const routes = {
  ...routesCore,
  ...routesOpCo,
};

const isMyRandstadEnabled = process.env.REACT_APP_MYRANDSTAD_ENABLED === 'true';
const isSavedJobIconEnabled = process.env.REACT_APP_ENABLE_SAVED_JOB_ICON === 'true';

// Get an array of supported languages
const languages = Object.keys(routes);

// Iterate through supported languages
languages.forEach((lang) => {
  // Get an array of supported routes for the current language
  const routeNames = Object.keys(routes[lang]);

  // Iterate through supported routes for the current language
  routeNames.forEach((routeName) => {
    const route = routes[lang][routeName];
    const Component = getComponent(route, routeName);

    if (Component) {
      // Add the route in the renderable array.
      routeComponents.push(
        <Route key={routeName} path={`${route.url}`} component={Component} />,
      );
    }
  });
});
// Everything that is not defined as route will lead to NotFound page.
routeComponents.push(<Route key="not-found" component={NotFound} />);
function App({
  currentLanguage,
  context,
  links,
  isEnableRelNavigation,
}) {
  // Get the language.
  const lang = currentLanguage || process.env.REACT_APP_DEFAULT_LANGUAGE;

  // Get the translations based on the language.
  let messages;
  try {
    messages = require(`../i18n/${process.env.REACT_APP_COUNTRY}/${lang}.json`);
  } catch (e) {
    // Use the translations from the default language if the file is missing.
    Logger.error(`Missing translation for: ${lang}`, 'App.js');
  }

  const tfHeaderProps = getTfHeaderProps(context);
  const marketingMessages = getMarketingMessages(context);

  if (process.env.REACT_APP_TF) {
    // Update default translations with the Shared components translations.
    messages = { ...messages, ...tfHeaderProps.tfTranslation };
  }
  const logoUrl = currentLanguage === process.env.REACT_APP_DEFAULT_LANGUAGE ? '' : currentLanguage;

  const navigationProps = {
    ...isMyRandstadEnabled && {
      myRandstad: {
        text: 'my randstad',
        href: '',
        user: null,
      },
    },
    ...isSavedJobIconEnabled && {
      savedJobs: {
        text: '0',
        href: '/',
      },
    },
    logo: {
      title: messages['MegaMenu.Logo.Title'],
      href: `/${logoUrl}`,
    },
    aria: {
      navigation: {
        menu: messages['MegaMenu.Aria.Navigation.Menu'],
        navigation: messages['MegaMenu.Aria.Navigation.Navigation'],
        close: messages['MegaMenu.Aria.Navigation.Close'],
      },
      megaMenu: {
        main: messages['MegaMenu.Aria.MegaMenu.Main'],
        close: messages['MegaMenu.Aria.MegaMenu.Close'],
      },
    },
  };

  const [myRandstadProps, setMyRandstadProps] = useState(navigationProps.myRandstad);
  const [savedJobsProps, setSavedJobsProps] = useState(navigationProps.savedJobs);
  const [itemProps, setItemProps] = useState(links?.items || []);

  useEffect(() => {
    document.querySelector('html').classList.add('js');
    /**
     * Add separate class for Talent Foundation, for further adjustments, see:
     * @link ./src/assets/src/js/orbit.js
     * @link ./src/assets/src/scss/_talent-foundation.scss
     * */
    if (process.env.REACT_APP_TF) {
      document.querySelector('html').classList.add('tf');
    }
  }, []);

  const updateNavigationProps = useCallback((newProps) => {
    if (isMyRandstadEnabled && newProps.myRandstad) {
      setMyRandstadProps(newProps.myRandstad);
    }

    if (newProps.myRandstadMenuItems) {
      setItemProps([...itemProps, (newProps.myRandstadMenuItems || {})]);
    }
  }, []);

  useEffect(() => {
    if (isMyRandstadEnabled) {
      setMyRandstadProps({
        text: window.myRandstadText,
        href: window.myRandstadUrl,
        user: window.myRandstadUsername ? {
          initials: window.myRandstadUsername?.charAt(0)?.toUpperCase(),
          names: window.myRandstadUsername,
        } : null,
      });
    }

    const updateSavedJobsCounter = () => setSavedJobsProps({
      text: window.relNavFavoritesCounter,
      href: window.relNavFavoritesUrl,
    });

    if (isSavedJobIconEnabled) {
      updateSavedJobsCounter();
      document.addEventListener('favoritesCounterUpdated', updateSavedJobsCounter);
    }

    // This event only dispatch in auth-widget of OPCOs it
    document.addEventListener('updateNavigationProps', (event) => {
      updateNavigationProps(event.detail);
    });

    return () => {
      document.removeEventListener('updateNavigationProps', (event) => {
        updateNavigationProps(event.details);
      });

      if (isSavedJobIconEnabled) {
        document.removeEventListener('favoritesCounterUpdated', updateSavedJobsCounter);
      }
    };
  }, []);

  /**
   * Implement datalayer events from REL for the Mega menu.
   */
  useEffect(() => {
    if (isEnableRelNavigation) {
      initEventsRel();
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    updateSecondLevelMenuActiveLink();
  }, [location]);

  return (
    <IntlProvider
      defaultLocale={process.env.REACT_APP_DEFAULT_LANGUAGE}
      locale={lang}
      messages={messages}
    >
      <TrackingProvider context={context}>
        {isEnableRelNavigation && (
          <Navigation
            {...navigationProps}
            myRandstad={myRandstadProps}
            savedJobs={savedJobsProps}
            {...links}
            items={itemProps}
          />
        )}
        {process.env.REACT_APP_TF && <Header tfHeaderProps={tfHeaderProps} />}
        <Switch>{routeComponents}</Switch>
        {process.env.REACT_APP_TF && (<MarketingMessages marketingMessages={marketingMessages} locale={lang} />)}
      </TrackingProvider>
    </IntlProvider>
  );
}

export default hot(App);
